import Collapse from "react-collapse";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as Scroll from "react-scroll";
import { Events, animateScroll as scroll, scrollSpy } from "react-scroll";

const ScrollLink = Scroll.Link;

const Brand = styled.button`
  color: rgba(255, 255, 255, 0.7) !important;
  text-decoration: none !important;
  padding: 0 1rem;
  ${"" /* y u no work! */}
  &:hover: {
    color: hotpink !important;
  }
`;
const HamburgerLink = styled.button`
  color: rgba(255, 255, 255, 0.7);
  padding: 0;
  text-decoration: none;
`;

const NavLink = styled.button`
  color: rgba(255, 255, 255, 0.7) !important;
  text-decoration: none !important;
  padding: 1em;
  ${"" /* y u no work! */}
  &:hover: {
    color: hotpink !important;
  }
  @media (max-width: 36em) {
    display: none;
  }
`;

const Header = ({ siteTitle }) => {
  const [isOpened, setIsOpened] = useState(false);
  useEffect(() => {
    Events.scrollEvent.register("begin", (to, element) => {
      // console.log("begin", arguments);
    });
    Events.scrollEvent.register("end", (to, element) => {
      // console.log("end", arguments);
    });
    scrollSpy.update();
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  // const scrollToBottom = () => {
  //   scroll.scrollToBottom();
  // };
  // const scrollTo = () => {
  //   scroll.scrollTo(100);
  // };
  // const scrollMore = () => {
  //   scroll.scrollTo(100);
  // };
  const handleSetActive = to => {
    // console.log(to);
  };
  const handleClick = () => {
    setIsOpened(!isOpened);
  };
  return (
    <nav
      className="nav-container d-flex flex-column w-100"
      // css={css`
      //   position: sticky;
      //   top: 0px;
      //   height: 64px;
      //   z-index: 9999;
      //   @media (max-width: 576px) {
      //     height: 50px;
      //   }
      // `}
    >
      <div>
        <div className="nav-content d-flex align-items-center justify-content-between px-3 px-sm-6">
          <div>
            <Brand
              className="btn btn-link"
              onClick={() => scrollToTop()}
              style={{ textDecoration: "none" }}
            >
              {siteTitle}
            </Brand>
          </div>
          <div>
            <button className="hamburger" onClick={() => handleClick()}>
              <i className="fas fa-bars" />
            </button>
            <ScrollLink
              activeClass="active"
              to="fp-reviews"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={handleSetActive}
            >
              <NavLink className="btn btn-link">False Positives</NavLink>
            </ScrollLink>
            <ScrollLink
              activeClass="active"
              to="ss-signup"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={handleSetActive}
            >
              <NavLink className="btn btn-link">Soul Surfer</NavLink>
            </ScrollLink>
            <ScrollLink
              activeClass="active"
              to="connect"
              spy={true}
              smooth={true}
              duration={1000}
              onSetActive={handleSetActive}
            >
              <NavLink className="btn btn-link">Connect</NavLink>
            </ScrollLink>
          </div>
        </div>
        <Collapse className="nav-hamburger-menu" isOpened={isOpened}>
          <ul className="nav-hamburger-menu-list p-2 m-0">
            <li className="p-2">
              <ScrollLink
                activeClass="active"
                to="fp-reviews"
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={handleSetActive}
              >
                <HamburgerLink className="btn btn-link" onClick={handleClick}>
                  False Positives
                </HamburgerLink>
              </ScrollLink>
            </li>
            <li className="p-2">
              <ScrollLink
                activeClass="active"
                to="ss-signup"
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={handleSetActive}
              >
                <HamburgerLink className="btn btn-link" onClick={handleClick}>
                  Soul Surfer
                </HamburgerLink>
              </ScrollLink>
            </li>
            <li className="p-2">
              <ScrollLink
                activeClass="active"
                to="connect"
                spy={true}
                smooth={true}
                duration={1000}
                onSetActive={handleSetActive}
              >
                <HamburgerLink className="btn btn-link" onClick={handleClick}>
                  Connect
                </HamburgerLink>
              </ScrollLink>
            </li>
          </ul>
        </Collapse>
      </div>
    </nav>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;
