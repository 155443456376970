import React from "react";
import "../utils/fonts/fa-brands";
import "../utils/fonts/fa-solid";
import "../utils/fonts/fontawesome";

const Footer = () => {
  return (
    <div
      className="d-flex flex-column w-100 mt-5"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className="d-flex align-items-center justify-content-between container py-2 py-sm-3">
        <div
          style={{
            fontFamily: "'Exo 2', sans-serif",
            color: "rgba(255,255,255,0.7",
          }}
        >
          &copy; Kim Aleksander {new Date().getFullYear()}
        </div>
        <div>
          <a
            style={{ fontSize: "1.33rem" }}
            href="https://www.facebook.com/AuthorKimAleksander/"
            title="Facebook"
          >
            <span className="fa-layers fa-fw">
              <i
                className="fa fa-square fa-inverse"
                data-fa-transform="shrink-1"
              />
              <i className="fab fa-facebook" style={{ color: "#3b5998" }} />
            </span>
          </a>
          <a
            href="https://www.goodreads.com/author/show/5416967.Kim_Aleksander"
            style={{ fontSize: "1.33rem", paddingLeft: ".5rem" }}
            title="GoodReads"
          >
            <span className="fa-layers fa-fw">
              <i
                className="fa fa-square"
                style={{ color: "#553b08" }}
                data-fa-transform="shrink-1"
              />
              <i className="fab fa-goodreads" style={{ color: "#edead7" }} />
            </span>
          </a>
          <a
            style={{
              color: "#1da1f2",
              fontSize: "1.33rem",
              paddingLeft: ".5rem",
            }}
            href="https://twitter.com/KimAleksander"
            title="Twitter"
          >
            <i className="fab fa-twitter" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
